import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import React, { useEffect, useState, useRef } from "react";
import { IMapSearchResult, ParcelResult } from "../../../types/search";
import useBoundsUI from "../../../hooks/useBoundsUI";
import {
  createHighlightGraphic,
  generateGraphicNumber,
  highlightGraphicNLabel,
} from "../../../utils/map";
import useTableInfiniteScroll from "../../../hooks/useTableInfiniteScroll";
import Polygon from "@arcgis/core/geometry/Polygon";
import { IParcel } from "../../../contexts/BoundsUI.context";
import Skeleton from "../../atoms/Skeleton";
import { useAuth } from "../../../hooks/useAuth";
import { Parcellightboxlayer } from "../../../layers/Layers";
import MapView from "@arcgis/core/views/MapView";
import { getExtendedParcel } from "../../../services/parcel";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<IMapSearchResult | ParcelResult>();

const SearchTable = ({
  data,
  onScrollToBottom,
  addUcc,
  isLoading,
}: {
  data: IMapSearchResult[] | ParcelResult[];
  onScrollToBottom?: () => void;
  className?: string;
  addUcc?: boolean;
  isLoading?: boolean;
}) => {
  const [selectedParcel, setSelectedParcel] = useState<ParcelResult | null>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const { isMarketIntelAdmin, isMarketIntelOps } = useAuth();

  const {
    mainMapView: { view },
    sidebarMenu: { setOpen },
    selection: { parcels, setParcels },
  } = useBoundsUI();

  const tableRef = useRef<HTMLTableElement>(null);
  useTableInfiniteScroll(tableRef, onScrollToBottom);

  const newColumns = [
    columnHelper.accessor("owner_name", {
      header: "Owner Name",
      size: 135,
    }),
    columnHelper.accessor("description", {
      header: "Description",
      size: 120,
    }),
    columnHelper.accessor("county", {
      header: "County",
      size: 120,
    }),
    columnHelper.accessor("mortgage", {
      header: "Mortgage",
      size: 110,
      cell: ({ row }) => {
        const mortgage = row.original.mortgage;
        return (
          <Link
            to={"/dashboard/mortgage?parcel_lid=" + row.original.parcel_lid}
            className={clsx(
              "font-medium rounded m-auto px-3 text-sm font-roboto py-2 flex justify-center",
              mortgage
                ? "!bg-boundsYellow-50 text-black "
                : "!bg-boundsGray-500 text-white"
            )}
            onClick={(e) => {
              if (!mortgage) {
                e.preventDefault();
              }
            }}
          >
            {mortgage ? "View" : "None"}
          </Link>
        );
      },
    }),
    ...(addUcc
      ? [
          columnHelper.accessor("ucc", {
            header: "UCC",
            cell: ({ row }) => {
              const ucc = row.original.ucc !== null && row.original.ucc !== "" && row.original.ucc !== "0";
              return (
                <Link
                  to={`/dashboard/ucc?parcel_lid=${row.original.parcel_lid}`}
                  className={clsx(
                    "font-medium rounded m-auto px-3 text-sm font-roboto py-2 flex justify-center",
                    ucc
                      ? "!bg-boundsYellow-50 text-black "
                      : "!bg-boundsGray-500 text-white"
                  )}
                  onClick={(e) => {
                    if (!ucc) {
                      e.preventDefault();
                    }
                  }}
                >
                  {ucc ? "View" : "None"}
                </Link>
              );
            },
          }),
        ]
      : []),

    ...(isMarketIntelAdmin() || isMarketIntelOps()
      ? [
          columnHelper.accessor("parcel_lid", {
            header: "Parcel id",
            cell: ({ getValue }) => (
              <>{getValue() == null ? "N/A" : getValue()}</>
            ),
          }),
        ]
      : []),
  ];

  const table = useReactTable({
    data,
    columns: newColumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleClick = async (parcel: ParcelResult) => {
    if (selectedParcel === parcel) {
      setSelectedParcel(null);
    } else {
      setSelectedParcel(parcel);
      setOpen(false);
    }
  };

  const parcelIsSelected = (parcel: ParcelResult) => {
    return parcels.some(
      (selectedParcel: IParcel) =>
        selectedParcel.attributes.PARCEL_LID === parcel.parcel_lid
    );
  };

  const getFeatures = async (parcelLid: string) => {
    const query = Parcellightboxlayer.createQuery();
    query.where = `Parcel_LID = '${parcelLid}'`;
    query.returnGeometry = true;

    const result = await Parcellightboxlayer.queryFeatures(query);
    return result;
  };

  const updateParcels = async (
    feature: __esri.Graphic,
    geometry: __esri.Geometry,
    view: MapView
  ) => {
    const updatedParcels = parcels.slice();
    const objectId = Number(feature.getAttribute("OBJECTID"));
    const polygonGraphic = createHighlightGraphic(geometry as Polygon);
    const labelGraphic = generateGraphicNumber(
      geometry as Polygon,
      updatedParcels.length + 1
    );

    if (!updatedParcels.some((item) => item.attributes.OBJECTID === objectId)) {
      const parcel = await getExtendedParcel(feature.attributes.PARCEL_LID);
      highlightGraphicNLabel(view, feature, updatedParcels.length + 1);
      updatedParcels.push({
        attributes: {
          OBJECTID: Number(objectId),
          PARCEL_LID: parcel?.parcel_lid,
          PARCEL_APN: parcel?.parcel_apn,
          PRIMARY_ASSESSMENT_LID: parcel?.primary_assessment_lid,
          UNIQUE_TAXAPN: parcel?.unique_taxapn,
          OWNER_NAME: parcel?.owner_name,
          OWNER_NAME_1: parcel?.owner_name_1,
          Legal_Full: parcel?.legal_full,
          COUNTY: parcel?.county,
          SECTION: parcel?.section,
          TOWNSHIP: parcel?.township,
          RANGE: parcel?.range,
          total_owned_acreage: parcel?.total_owned_acreage,
          total_mortgage_acreage: parcel?.total_mortgage_acreage,
          total_mortgage_amount: parcel?.total_mortgage_amount,
          ACREAGE: parcel?.acreage,
          Min_: parcel?.min_,
          Max_: parcel?.max_,
          loan_institution: parcel?.loan_institution,
          Mortgage: parcel?.mortgage,
          mortgage_date: parcel.mortgage_date,
          mortgage_amount: parcel.mortgage_amount,
          UCC: parcel?.ucc,
        },
        graphic: polygonGraphic,
        geometry: geometry as __esri.Polygon,
        label: { id: objectId, graphic: labelGraphic },
        county: parcel?.county,
      });
    }

    return updatedParcels;
  };

  useEffect(() => {
    const handleUpdates = async () => {
      if (selectedParcel && view && selectedParcel.parcelFeature) {
        const result = await getFeatures(selectedParcel.parcel_lid);
        if (result.features.length > 0) {
          const feature = result.features[0];
          const geometry = feature.geometry;
          const newParcels = await updateParcels(feature, geometry, view);
          setParcels(newParcels);

          // Add both the polygon and the text graphic to the view
          const { extent } = selectedParcel.parcelFeature.geometry;
          const size = Math.max(extent.width, extent.height);
          let zoom = 15;
          if (size < 50) {
            zoom = 20;
          } else if (size < 150) {
            zoom = 19;
          } else if (size < 300) {
            zoom = 18;
          } else if (size < 900) {
            zoom = 17;
          } else if (size < 1800) {
            zoom = 16;
          }
          view.goTo({ target: extent, zoom });
        }
      }
    };
    handleUpdates();
  }, [selectedParcel]);

  return (
    <div className="overflow-auto max-h-[80vh]">
      <table className="border-b border-boundsPurple-100" ref={tableRef}>
        <thead className="border-b border-boundsPurple-100">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="bg-boundsPurple-100">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={clsx(
                    "px-2 py-3 border-b border-boundsPurple-100 bg-boundsPurple-100 sticky top-0  z-10"
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{ minWidth: header.getSize() }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="border border-boundsPurple-100">
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr
              key={row.id}
              className={clsx(
                "cursor-pointer",
                parcelIsSelected(row.original as ParcelResult) &&
                  "bg-boundsBlue-200"
              )}
              onClick={
                "parcelFeature" in row.original
                  ? () => {
                      handleClick(row.original as ParcelResult);
                    }
                  : undefined
              }
              onMouseOver={
                "parcelFeature" in row.original
                  ? (event) => {
                      event.currentTarget.classList.add("bg-boundsBlue-200");
                    }
                  : undefined
              }
              onMouseOut={
                "parcelFeature" in row.original
                  ? (event) => {
                      if (!parcelIsSelected(row.original as ParcelResult)) {
                        event.currentTarget.classList.remove(
                          "bg-boundsBlue-200"
                        );
                      }
                    }
                  : undefined
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={clsx(
                    "p-2 ",
                    rowIndex != 0 && "border-t border-t-boundsPurple-100",
                    "border-r border-boundsPurple-100 "
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {isLoading &&
            Array.from({ length: 5 }).map((_, rowIndex) => (
              <tr key={`skeleton-${rowIndex}`}>
                {newColumns.map((_, colIndex) => (
                  <td
                    key={`skeleton-${rowIndex}-${colIndex}`}
                    className="p-2 border-t border-t-boundsPurple-100 border-r border-boundsPurple-100"
                  >
                    <Skeleton width={"100%"} height={35} borderRadius={10} />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SearchTable;
