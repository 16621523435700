import axios from "../api";
import { IAuthStatus } from "../types/auth.types";

export const checkAuthStatus = async (): Promise<IAuthStatus | null> => {
  try {
    const response = await axios.get("/api/auth/check");
    return response.data;
  } catch (error) {
    return null;
  }
};

export const login = async (
  username: string,
  password: string
): Promise<IAuthStatus | null> => {
  try {
    const response = await axios.post("/api/auth/login", {
      username: username,
      password: password,
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      return error.response?.data;
    }
    return null;
  }
};

export const logout = async () => {
  try {
    const response = await axios.post("/api/auth/logout");
    return response.data;
  } catch (error) {
    return null;
  }
};

export const checkPasswordResetToken = async (email: string, token: string) => {
  try {
    const response = await axios.get(`/api/auth/check-password-token?email=${encodeURIComponent(email)}&token=${token}`);
    return response.status == 200;
  } catch (error) {
    return false;
  }
}

export const forgotPassword = async (email: string) => {
  try {
    const response = await axios.post("/api/auth/forgot-password", {
      email: email,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const resetPassword = async (email: string, token: string, password: string) => {
  try {
    const response = await axios.post("/api/auth/reset-password", {
      email: email,
      token: token,
      password: password,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};