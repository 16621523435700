import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'; 
import { Tooltip } from "react-tooltip";

const WaterWells = () => {
  const {
    featureLayer: { setGroup },
    insightData: { data, insightError },
  } = useBoundsUI();

  useEffect(() => {
    setGroup({
      groupName: "WaterWellsGroupLayer",
      layers: ["WaterWellsKansasLayer","WaterWellsMissouriLayer"],
    });
  }, []);
  // Unmount
  useEffect(
    () => () => setGroup({ groupName: "WaterWellsGroupLayer", layers: null }),
    []
  );

  return (
    <div className="min-w-[436px]">
      <div className="flex flex-row items-center">
          <h2 className="text-[32px]  mr-1">Water</h2>
          <FontAwesomeIcon className="question-mark" icon={faCircleQuestion} />
          <Tooltip className="max-w-[400px]" anchorSelect=".question-mark" place="bottom" offset={10}>
             The Water Well Completion Form Database (WWC5) contains information from records submitted by water well drillers to the Kansas Department of Health and Environment. Data is compiled by the Kansas Geological Survey.
          </Tooltip>
			</div>
      {insightError ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          {insightError}
        </p>
      ) : (
        <>
          <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
            Select a point to view data
          </p>
        </>
      )}
    </div>
  );
};

export default WaterWells;
