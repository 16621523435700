import React, { useEffect, useState, useRef } from "react";
import { generateTimeOptions } from "../../../../utils/financialOverview";
import { IOption } from "../../../../types/search";
import useClickOutside from "../../../../hooks/useClickOutside";
import DateRangePicker from "../../../molecules/DateRangePicker";

const MINIMUM_TIMEFRAME_DAYS = 30;

const DateRangeSelect = ({
  setStartDate,
  setEndDate,
  setTimePeriod,
}: {
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setTimePeriod: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [showPresetDateRanges, setShowPresetDateRanges] = useState(false);
  const [label, setLabel] = useState<string>("This Year");
  const [localStartDate, setLocalStartDate] = useState<Date | null>(null);
  const [localEndDate, setLocalEndDate] = useState<Date | null>(null);

  // Closes the select menu when user clicks outside of the component
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, () => {
    setShowPresetDateRanges(false);
  });

  const presetDateRangeOptions = generateTimeOptions();

  const selectPresetDateRange = (presetOption: IOption) => {
    const newStartDate = new Date(presetOption.value);
    let newEndDate = new Date(
      newStartDate.getFullYear(),
      newStartDate.getMonth(),
      1
    );
    setStartDate(newStartDate);
    if (presetOption.label === "This Month") {
      newEndDate.setMonth(newEndDate.getMonth() + 1);
    } else if (presetOption.label === "This Year") {
      newEndDate = new Date(newStartDate.getFullYear() + 1, 0, 1);
    } else if (presetOption.label === "This Quarter") {
      newEndDate = new Date(
        newStartDate.getFullYear(),
        Math.floor(newStartDate.getMonth() / 3) * 3 + 3,
        1
      );
    }
    setEndDate(newEndDate);
    setTimePeriod(presetOption.label.substring(5));
    setLabel(presetOption.label);
    setShowPresetDateRanges(false);
  };

  useEffect(() => {
    if (!localStartDate || !localEndDate) return;
    setLabel(
      localStartDate.toLocaleDateString() +
        " - " +
        localEndDate.toLocaleDateString()
    );
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    setTimePeriod("period");
    setShowPresetDateRanges(false);
  }, [localStartDate, localEndDate]);

  return (
    <div
      className="min-w-32 xl:min-w-40
        rounded-t-md border-b relative
        bg-boundsPurple-150 border-boundsYellow-50"
      ref={wrapperRef}
    >
      <div
        className="py-2 px-4 cursor-pointer"
        onClick={() => {
          if (showPresetDateRanges) {
            setShowPresetDateRanges(false);
          } else {
            setShowPresetDateRanges(true);
          }
        }}
      >
        <label className="text-xs">Date</label>
        <div>{label}</div>
      </div>
      {showPresetDateRanges && (
        <div
          className="absolute left-0 bg-boundsPurple-150 min-w-full z-20 pt-2"
          style={{
            top: "calc(100% + 1px)",
          }}
        >
          <ul>
            {presetDateRangeOptions.map((option) => (
              <li
                key={option.label}
                className="cursor-pointer px-4 py-1"
                onClick={() => selectPresetDateRange(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
          <div className="text-center px-4 py-2">- or -</div>
          <div className="px-4">Exact Date Range:</div>
          <DateRangePicker
            setStartDate={setLocalStartDate}
            setEndDate={setLocalEndDate}
            minDays={MINIMUM_TIMEFRAME_DAYS}
            errorMsg={`Timeframe must be at least ${MINIMUM_TIMEFRAME_DAYS} days`}
            startDate={localStartDate}
            endDate={localEndDate}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangeSelect;
