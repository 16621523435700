import React from "react";
import { components, MenuListProps } from "react-select";

interface SearchButtonProps extends MenuListProps<any, boolean> {
  onClick: () => void;
}

const SearchButton = (props: SearchButtonProps) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      <div
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "#4A3244",
          padding: "8px",
          zIndex: 1,
        }}
      >
        <button
          className="bg-boundsYellow-50 text-black px-2 rounded-lg w-full"
          onClick={() => {
            props.onClick();
            props.selectProps.onMenuClose();
          }}
        >
          Search
        </button>
      </div>
    </components.MenuList>
  );
};

export default SearchButton;
