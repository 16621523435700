import { GroupBase, StylesConfig } from "react-select";
import { IOption } from "../../../types/search";

const getStylesConfig = (
  variant: "primary" | "secondary"
): StylesConfig<IOption, boolean, GroupBase<IOption>> => ({
  control: (styles) => ({
    ...styles,
    backgroundColor: "none",
    border: "none",
    boxShadow: "none",
    minHeight: "auto",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: variant === "secondary" ? "#000" : "#CAC4D0",
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
    fontSize: 16,
    cursor: "pointer",
    maxHeight: 80,
    overflow: "auto",
    flexWrap: "nowrap",
  }),
  menu: (styles) => ({
    ...styles,
    color: variant === "secondary" ? "#000" : "#CAC4D0",
    backgroundColor: variant === "secondary" ? "#CAC4D0" : "#4F3949",
    width: "120%",
    top: "113%",
    left: "-16px",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#584274"
        : isFocused
        ? "#584274"
        : undefined,
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#CAC4D0"
            : "#584274"
          : undefined,
      },
    };
  },
  input: (styles) => ({
    ...styles,
    color: variant === "secondary" ? "#000" : "#CAC4D0",
    cursor: "pointer",
    padding: 0,
    margin: 0,
    minWidth: 20,
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    background: data.label ? "#6d5a68" : "transparent",
    paddingLeft: data.label ? 6 : 0,
    display: data.label ? "flex" : "none",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#CAC4D0",
    padding: 0,
    fontSize: 13,
    maxWidth: 100,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: variant === "secondary" ? "#000000" : "#a19fa2",
    cursor: "pointer",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    ">div": {
      padding: 0,
      svg: {
        fill: variant === "secondary" ? "#000" : "#CAC4D0",
      },
    },
  }),
});

export default getStylesConfig;
