import React, { FC, ReactNode } from "react";
import ModalComponent from "react-modal";

interface IModal {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
  width?: number | string;
  overflow?: string;
  minHeight?: string | number;
}

const Modal: FC<IModal> = ({
  isOpen,
  children,
  onClose,
  width = 800,
  overflow = "auto",
  minHeight = "auto",
}) => {
  if (!isOpen) return null;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      overflow: overflow,
      maxHeight: 780,
      minHeight: minHeight || "fit-content",
      width: width,
      background: "#200519",
      color: "#FFF",
      padding: 30,
    },
    overlay: {
      zIndex: 20,
      backgroundColor: "rgb(82 81 80 / 42%)",
    },
  };

  return (
    <ModalComponent
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      {children}
    </ModalComponent>
  );
};

export default Modal;
