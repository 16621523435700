import React from "react";
import Input from "../../atoms/Input";
import { SelectComponent } from "../../atoms/Select";
import {
  IOption,
  ISearchFormValues,
  SearchFieldLocationName,
} from "../../../types/search";
import { Control, FieldValues, UseFormRegister } from "react-hook-form";
import DatePicker from "../../molecules/DatePicker";
import { ComponentType } from "../../../utils/mortgage";
import SearchSelect from "../../molecules/Sidebar/Search/SearchSelect";

const DynamicInput = ({
  name,
  options,
  control,
  component,
  register,
  ...rest
}: {
  name: string;
  options?: IOption[];
  label: string;
  control?: Control<any>;
  component: ComponentType;
  errorMessage?: string;
  isMulti?: boolean;
  register: UseFormRegister<any>;
  readOnly?: boolean;
}) => {
  const renderInput = (component: ComponentType) => {
    switch (component) {
      case "Select":
        return (
          <SelectComponent
            name={name}
            control={control as Control<FieldValues>}
            {...rest}
            options={options || []}
          />
        );
      case "Input":
        return <Input {...rest} {...register(name)} />;
      case "DatePicker":
        return (
          <DatePicker
            control={control as Control<FieldValues>}
            name={name}
            {...rest}
          />
        );
      case "SearchSelect":
        return (
          <SearchSelect
            searchInput={{
              ...rest,
              name: name as SearchFieldLocationName,
              isMulti: rest.isMulti as boolean,
            }}
            isSubmitting={false}
            control={control as Control<ISearchFormValues>}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderInput(component)}</>;
};

export default DynamicInput;
