import React from "react";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import CustomContent from "@arcgis/core/popup/content/CustomContent";
import CityPopup from "../components/features/CityPopup";
import { createRoot, Root } from "react-dom/client";
import { getMortgageHolders } from "../services/dashboard";
import { PAGE_SIZE } from "../utils/constants";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "../redux/store";
import Skeleton from "../components/atoms/Skeleton";

let page = 1;
const rootMap = new Map<Element, Root>();

export const cityDonutCustomContent = new CustomContent({
  outFields: ["*"],
  creator: (feature) => {
    const cityName = feature && feature.graphic.attributes.minorciv_1;
    const container = document.createElement("div");

    // Check if this is a new modal or an existing one
    if (!rootMap.has(container)) {
      page = 1; // Reset the page to 1 for new modal
    }

    const fetchAndRender = async (pageNumber: number) => {
      const response = await getMortgageHolders({
        page: pageNumber,
        page_size: PAGE_SIZE,
        sort_by: "row",
        sort_order: "asc",
        params: {
          city: cityName,
          parcel_lid: "any",
        },
      });

      if (response && response.total_count > 0) {
        const handlePageChange = (newPage: number) => {
          page = newPage;
          fetchAndRender(newPage);
        };

        // Check if a root already exists for this container
        let root = rootMap.get(container);
        if (!root) {
          root = createRoot(container);
          rootMap.set(container, root);
        }
        ReactDOM.createRoot(container).render(
          <Provider store={store}>
            <CityPopup
              actualPage={page}
              data={response.data}
              totalCount={response.total_count}
              handlePageChange={handlePageChange}
              loadingPagination={false}
            />
          </Provider>
        );
      } else {
        // Handle case where no data is found
        container.innerHTML = "<div>No data found</div>";
      }
    };

    fetchAndRender(page);
    ReactDOM.createRoot(container).render(
      <Skeleton borderRadius={10} height={300} />
    );

    return container;
  },
});

const cityDonutPopupTemplate = {
  title: `<div class="flex justify-center items-center">
            <div class="">Recent Mortgages</div>
          </div>`,
  content: [cityDonutCustomContent],
  overwriteActions: true,
};

export const CityDonutLayer = new FeatureLayer({
  id: "CityDonutLayer",
  maxScale: 120828,
  minScale: 1317305,
  portalItem: {
    id: "8f16e0a35a4d48eeb85e5f6488e94e5c",
  },
  visible: true,
  popupTemplate: cityDonutPopupTemplate,
});
