import React from "react";
import { MultiValueProps, components } from "react-select";
import { IOption } from "../../../../types/search";

const CustomMultiValue = (props: MultiValueProps<IOption, boolean>) => {
  const selectedOptions = props.selectProps.value as IOption[];
  const maxDisplayCount = 2;

  if (
    selectedOptions.length > maxDisplayCount &&
    props.data === selectedOptions[0]
  ) {
    return (
      <components.MultiValue {...props}>
        <span>{`${selectedOptions.length} items selected`}</span>
      </components.MultiValue>
    );
  }

  if (selectedOptions.length > maxDisplayCount) {
    return null;
  }

  return <components.MultiValue {...props} />;
};

export default CustomMultiValue;
