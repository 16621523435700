import Point from "@arcgis/core/geometry/Point";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { getParcelSearch } from "../../../../services/parcel";
import {
  IMapSearchQuery,
  ISearchFormValues,
  ParcelResult,
  SearchFieldIdName,
  SearchFieldLocationName,
} from "../../../../types/search";
import Button from "../../../atoms/Button";
import Results from "../../../features/Results";
import { searchFormDefaultValues, searchInputs } from "./SearchInputs";
import SearchParcelSelect from "./SearchParcelSelect";
import SearchSelect from "./SearchSelect";
import { NavigationGroupLayer } from "../../../../layers/Layers";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const PARCEL_RESULTS_RESPONSE_COUNT = 50;

const targetPoint = new Point({
  longitude: -97.336111,
  latitude: 37.688889,
  spatialReference: { wkid: 4326 },
});

const parcelLayer = NavigationGroupLayer.findLayerById(
  "Parcellightboxlayer"
) as FeatureLayer;

const Search = ({ closeWidget }: { closeWidget: () => void }) => {
  const [parcelResults, setParcelResults] = useState<ParcelResult[]>([]);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [hasMaxResults, setHasMaxResults] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);

  const {
    sidebarMenu: { setIsSubmenuOpen, isSubmenuOpen: isResultsOpen },
  } = useBoundsUI();

  const { control, handleSubmit, reset, setValue } = useForm<ISearchFormValues>(
    { defaultValues: searchFormDefaultValues }
  );
  const formValues = useWatch({ control }) as ISearchFormValues;

  useEffect(() => {
    setIsSubmenuOpen(false);
  }, []);

  const cancelSearch = () => {
    reset();
    closeWidget();
  };

  const clearFilters = () => {
    reset({ ...searchFormDefaultValues });
  };

  const getMapSearchQuery = (
    page: number,
    page_size: number,
    sort_by?: string,
    sort_order?: string
  ): IMapSearchQuery => {
    return {
      parcel_lid: formValues[SearchFieldIdName.ParcelLid]?.map(
        (option) => option.value
      ),
      parcel_apn: formValues[SearchFieldIdName.Parcel]?.map(
        (option) => option.value
      ),
      unique_taxapn: formValues[SearchFieldIdName.UniqueTax]?.map(
        (option) => option.value
      ),
      alternate_taxapn: formValues[SearchFieldIdName.AlternateTax]?.map(
        (option) => option.value
      ),
      owner_name: formValues[SearchFieldLocationName.OwnerName]?.map(
        (option) => option.value
      ),
      site_address: formValues[SearchFieldLocationName.OwnerAddress]?.map(
        (option) => option.value
      ),
      county: formValues[SearchFieldLocationName.County]?.map(
        (option) => option.value
      ),
      section: formValues[SearchFieldLocationName.Section]?.map(
        (option) => option.value
      ),
      township: formValues[SearchFieldLocationName.Township]?.map(
        (option) => option.value
      ),
      range: formValues[SearchFieldLocationName.Range]?.map(
        (option) => option.value
      ),
      block_number: formValues[SearchFieldLocationName.Block]?.map(
        (option) => option.value
      ),
      lot_number: formValues[SearchFieldLocationName.Lot]?.map(
        (option) => option.value
      ),
      site_state: formValues[SearchFieldLocationName.State]?.value || "KS",
      page,
      page_size,
      sort_by: sort_by ?? "parcel_lid",
      sort_order: sort_order ?? "desc",
    };
  };

  const queryParcel = async (page: number, existingResults: ParcelResult[]) => {
    page === 0 ? (page = 1) : "";
    const searchFields: IMapSearchQuery = getMapSearchQuery(
      page,
      PARCEL_RESULTS_RESPONSE_COUNT
    );

    try {
      setIsLoadingResults(true);
      const parcels = await getParcelSearch(searchFields);

      const parcelPromises = parcels.data.map(async (parcel: ParcelResult) => {
        try {
          // get parcelFeature from ArcGIS
          const parcelFeatureQuery = parcelLayer.createQuery();
          parcelFeatureQuery.where = `PARCEL_LID = '${parcel.parcel_lid}'`;
          parcelFeatureQuery.returnGeometry = true;
          const result = await parcelLayer.queryFeatures(parcelFeatureQuery);

          const parcelFeature = result.features.length
            ? result.features[0]
            : null;

          return {
            parcel_lid: parcel.parcel_lid,
            owner_name: parcel.owner_name,
            description: `STR: ${
              parcel.section || parcel.township || parcel.range 
                ? `${parcel.section ?? ''} ${parcel.township ?? ''} ${parcel.range ?? ''}  `
                : 'N/A'
                
            } ${parcel.block_number || parcel.lot_number ?` B:${parcel.block_number ?? ''} L:${parcel.lot_number ?? ''} ` : ''}`,
            county: parcel.county,
            mortgage: parcel.mortgage ?? null,
            ucc: parcel.ucc,
            parcelFeature,
          };
        } catch (error) {
          console.error(
            `Error fetching parcelFeature for ${parcel.parcel_lid}`,
            error
          );
          return {
            parcel_lid: parcel.parcel_lid,
            owner_name: parcel.owner_name,
            description: `STR: ${
              parcel.section || parcel.township || parcel.range 
                ? `${parcel.section ?? ''} ${parcel.township ?? ''} ${parcel.range ?? ''}  `
                : 'N/A'
                
            } ${parcel.block_number || parcel.lot_number ?` B:${parcel.block_number ?? ''} L:${parcel.lot_number ?? ''} ` : ''}`,
            county: parcel.county,
            mortgage: parcel.mortgage ?? null,
            ucc: parcel.ucc,
            parcelFeature: null,
          };
        }
      });

      const formattedParcels = await Promise.allSettled(parcelPromises);

      const successfulParcels = formattedParcels
        .filter((result) => result.status === "fulfilled")
        .map((result: any) => result.value);

      setHasMaxResults(parcels.data.length < PARCEL_RESULTS_RESPONSE_COUNT);
      setParcelResults([...existingResults, ...successfulParcels]);
      setPageOffset(page);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }

    setIsLoadingResults(false);
  };

  const loadMoreData = () => {
    if (!isLoadingResults && !hasMaxResults) {
      queryParcel(pageOffset + 1, parcelResults);
    }
  };

  const onSubmit = () => {
    setParcelResults([]);
    queryParcel(0, []);
    setIsSubmenuOpen(true);
  };

  return (
    <>
      {isResultsOpen ? (
        <Results
          data={parcelResults}
          loadMoreData={loadMoreData}
          isLoading={isLoadingResults}
          point={targetPoint}
        />
      ) : (
        <>
          <h1 className="text-[32px]">Search</h1>
          <span
            onClick={clearFilters}
            className="ml-auto text-sm text-boundsYellow-50 cursor-pointer"
          >
            clear filters
          </span>

          <form
            className="mt-3 flex flex-col gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            {searchInputs.slice(0, 2).map((searchInput, index) => {
              const showLocationTitle = index === 2;
              return (
                <SearchSelect
                  key={searchInput.name}
                  searchInput={searchInput}
                  control={control}
                  isSubmitting={isLoadingResults}
                  title={showLocationTitle ? "Location" : undefined}
                  rules={
                    searchInput.required
                      ? { required: "This field is required" }
                      : undefined
                  }
                />
              );
            })}
            <SearchParcelSelect
              control={control}
              setValue={setValue}
              isSubmitting={isLoadingResults}
            />
            {searchInputs.slice(2).map((searchInput, index) => {
              const showLocationTitle = index === 0;
              return (
                <SearchSelect
                  key={searchInput.name}
                  searchInput={searchInput}
                  control={control}
                  isSubmitting={isLoadingResults}
                  title={showLocationTitle ? "Location" : undefined}
                />
              );
            })}
            <div className="flex gap-7">
              <Button
                label={"Cancel"}
                variant="secondary"
                onClick={cancelSearch}
              />
              {isLoadingResults ? (
                <Button
                  label={"Results"}
                  onClick={() => setIsSubmenuOpen(true)}
                />
              ) : (
                <Button
                  label={"Search"}
                  type={"submit"}
                  isLoading={isLoadingResults}
                />
              )}
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Search;
