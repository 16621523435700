import React, { useEffect } from "react";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import "./Elevation.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "react-tooltip";

const Elevation = () => {
  const {
    featureLayer: { setGroup },
    insightData: { data, insightError },
  } = useBoundsUI();
  const isLoadingData = data === null;

  useEffect(() => {
    setGroup({
      groupName: "ElevationGroupLayer",
      layers: ["ElevationDEMLayer"],
    });
  }, []);
  //unmount
  useEffect(
    () => () => setGroup({ groupName: "ElevationGroupLayer", layers: null }),
    []
  );
  const min = data && data.length > 0 ? data[0].minElevation : 0;
  const max = data && data.length > 0 ? data[0].maxElevation : 0;
  const minSlope = data && data.length > 0 ? data[0].minSlope : 0;
  const maxSlope = data && data.length > 0 ? data[0].maxSlope : 0;
  const meanSlope = data && data.length > 0 ? data[0].meanSlope : 0;

  return (
    <div className="min-w-[285px]">
      <div className="flex flex-row items-center ">
        <h2 className="text-[32px] mr-1">Elevation</h2>
        <FontAwesomeIcon className="question-mark" icon={faCircleQuestion} />
        <Tooltip anchorSelect=".question-mark" place="bottom" offset={10}>
          Source: USGS
        </Tooltip>
      </div>
      {insightError ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          {insightError}
        </p>
      ) : isLoadingData ? (
        <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
          Loading...
        </p>
      ) : data && data.length > 0 ? (
        <div className="border-t-[1px] border-boundsYellow-50 py-1 mt-6">
          <div className="py-6">
            <div className="rainbow-bar"></div>
          </div>
          <div className="flex justify-between">
            <span>664 ft</span>
            <span>4120 ft</span>
          </div>
          <h2 className="mt-3 text-[20px]">Contour Interval (feet):</h2>
          10
          <h2 className="mt-3 text-[20px] font-medium">Range</h2>
          <b>{Math.round(10 * (max - min)) / 10} ft</b>
          <br />
          <strong>Min</strong> {min} ft
          <br />
          <strong>Max</strong> {max} ft
          <br />
          <h2 className="mt-3 text-[20px] font-medium">Slope</h2>
          <strong>{meanSlope}%</strong>
          <br />
          <strong>Min</strong> {minSlope}%
          <br />
          <strong>Max</strong> {maxSlope}%
          <br />
        </div>
      ) : (
        <>
          <p className="border-y-[1px] border-boundsYellow-50 py-5 mt-6">
            Select an area to view data
          </p>
        </>
      )}
    </div>
  );
};

export default Elevation;
