import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getInterestRates, rateAnalysis } from "../../../../services/dashboard";
import { MORTGAGE_RATE_OPTIONS, PAGE_SIZE } from "../../../../utils/constants";
import {
  IRateAnalysisParams,
  RateAnalysisResponse,
} from "../../../../types/mortgage";
import DashboardTable from "../DashboardTable";
import Input from "../../../atoms/Input";
import { SelectComponent } from "../../../atoms/Select";
import { useForm } from "react-hook-form";
import Title from "../../../atoms/Title";
import { tableSearchSchema } from "../../../../schemas/dashboard";
import { yupResolver } from "@hookform/resolvers/yup";
import useDebounce from "../../../../hooks/useDebounce";
import { IOption } from "../../../../types/search";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import { IColumn, ISort } from "../DashboardTable/DashboardTable";
import { formatInterestRate, formatMoney } from "../../../../utils/string";
import clsx from "clsx";
import { getDateOnly } from "../../../../utils/financialOverview";
import { formatDate } from "../../../../utils/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setSelectedParcelLId } from "../../../../redux/actions";

const MortgageAnalysis = () => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const [queryParams, setQueryParams] = useSearchParams();
  const queryCategory = queryParams.get("category");
  const queryText = queryParams.get("search");
  const queryParcelLid = queryParams.get("parcel_lid");

  const getInitialSearchCategoryOption = () => {
    return MORTGAGE_RATE_OPTIONS.find((item) => item.value === queryCategory);
  };
  const { control, register, watch, setValue, getValues } = useForm({
    resolver: yupResolver(tableSearchSchema),
  });
  const {
    sidebarMenu: { setOpen },
  } = useBoundsUI();

  const [data, setData] = useState<RateAnalysisResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingPagination, setLoadingPagination] = useState(true);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState<{ [x: string]: string }>(
    queryCategory && queryText ? { [queryCategory]: queryText } : {}
  );
  const [sort, setSort] = useState<ISort>({
    by: "date",
    order: "desc",
  });
  const debouncedSort = useDebounce(sort, 500);
  const [todayInterestRate, setTodayInterestRate] = useState<number>();
  const today = new Date();

  const category = watch("searchCategory") as unknown as IOption;
  const inputSearch = watch("search");
  const debouncedInputSearch = useDebounce(inputSearch, 1000);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleParcelLId = (parcel_lid: string) => {
    dispatch(setSelectedParcelLId(parcel_lid));
    navigate("/");
  };

  const clearSearch = () => {
    setValue("searchCategory", "");
    setValue("search", "");
    setSearchParams({});
    queryParams.delete("category");
    queryParams.delete("search");
    queryParams.delete("parcel_lid");
    setQueryParams(queryParams);
  };

  useEffect(() => {
    setOpen(false);
    if (queryText) {
      setValue("search", queryText);
    }

    getInterestRates({
      start_date: getDateOnly(today),
      end_date: getDateOnly(today),
    }).then((response) => {
      if (response && response.length > 0) {
        setTodayInterestRate(parseFloat(response[0].prime_rate));
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    const loadRateAnalysis = async (params: IRateAnalysisParams) => {
      try {
        const result = await rateAnalysis(params, signal);
        if (!signal.aborted) {
          setData(result);
          setLoading(false);
          setLoadingPagination(false);
        }
      } catch (error) {
        console.error("Failed to fetch rate analysis data:", error);
        setLoading(false);
        setLoadingPagination(false);
      }
    };

    if (queryParcelLid) {
      loadRateAnalysis({
        page,
        page_size: PAGE_SIZE,
        sort_by: sort.by,
        sort_order: sort.order,
        parcel_lid: queryParcelLid,
      });
    } else {
      loadRateAnalysis({
        page,
        page_size: PAGE_SIZE,
        sort_by: sort.by,
        sort_order: sort.order,
        ...searchParams,
      });
    }

    return () => {
      abortController.abort();
    };
  }, [page, searchParams, debouncedSort, queryParcelLid]);

  useEffect(() => {
    setPage(1);
  }, [debouncedSort]);

  useEffect(() => {
    if (category && debouncedInputSearch) {
      setLoadingPagination(true);
      setSearchParams({ [category.value]: debouncedInputSearch });
      setQueryParams({
        category: category.value,
        search: debouncedInputSearch,
      });
      setPage(1);
    }
  }, [category, debouncedInputSearch]);

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const handleSort = (field: string) => {
    setLoading(true);
    if (page > 1) {
      setPage(1);
    }
    if (field === sort.by) {
      setSort({
        by: field,
        order: sort.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSort({
        by: field,
        order: "asc",
      });
    }
  };

  const columns = React.useMemo<IColumn[]>(
    () => [
      {
        field: "issue",
        sortField: "issue",
        sortable: true,
        header: "Map",
        minWidth: 60,
        additionalFields: ["parcel_lid"],
        transform: (
          val: string,
          extra?: { [key: string]: any }
        ): string | number | JSX.Element => {
          if (val) return <>Not Available</>;

          return (
            <div
              className="flex items-center cursor-pointer"
              onClick={() => handleParcelLId(extra?.parcel_lid)}
            >
              <FontAwesomeIcon className="question-mark" icon={faMap} />
            </div>
          );
        },
      },
      {
        field: "type",
        sortField: "type",
        header: "Type",
      },
      {
        field: "date",
        sortField: "time_since",
        header: "Time Since",
        transform: (val: string) => {
          if (val) {
            const date = new Date(val);
            return (
              Math.floor(
                (today.getTime() - date.getTime()) / (24 * 60 * 60 * 1000)
              ) + " days"
            );
          } else {
            return "N/A";
          }
        },
        minWidth: 100,
      },
      {
        field: "loan_institution",
        sortField: "loan_institution",
        header: "Loan Institution",
      },
      {
        field: "borrower",
        sortField: "borrower",
        header: "Borrower",
      },
      {
        field: "description",
        header: "Description",
      },
      {
        field: "county",
        sortField: "county",
        header: "County",
        minWidth: 100,
      },
      {
        field: "date",
        sortField: "date",
        header: "Date",
        transform: (val: string) => (val ? formatDate(val) : "N/A"),
        minWidth: 120,
      },
      {
        field: "mortgage_amount",
        sortField: "mortgage_amount",
        header: "Mortgage Amount",
        transform: (val: number) => {
          return val ? `${formatMoney(val)}` : "N/A";
        },
      },
      {
        field: "interest_rate",
        additionalFields: ["exact"],
        sortField: "interest_rate",
        header: "Interest Rate",
        transform: (val: string, extra: { [key: string]: any } | undefined) => {
          const formattedRate = formatInterestRate(val);
          return (
            <>
              <div
                className={clsx(
                  "font-medium",
                  formattedRate.includes("-")
                    ? "text-red-500"
                    : "text-green-500"
                )}
              >
                {formattedRate}
              </div>
              {extra && (
                <div className="text-xs">
                  {extra["exact"] ? "(Actual)" : "(Estimated)"}
                </div>
              )}
            </>
          );
        },
      },
      {
        field: "interest_rate",
        sortField: "per_change",
        header: "% of Change",
        transform: (val: string) => {
          if (todayInterestRate) {
            const difference = todayInterestRate - parseFloat(val);
            const formattedRate = formatInterestRate(difference.toString());
            return (
              <div
                className={clsx(
                  "font-medium",
                  formattedRate.includes("-")
                    ? "text-red-500"
                    : "text-green-500"
                )}
              >
                {formattedRate}
              </div>
            );
          }
          return <div></div>;
        },
      },
    ],
    [todayInterestRate]
  );

  return (
    <div className="p-8">
      <div className="border-4 border-boundsPurple-500 bg-boundsPurple-600 py-6 px-10 rounded-[30px]">
        <div className="flex justify-between">
          <Title label="Mortgage Rate Analysis" />
          <form className="flex gap-7">
            <SelectComponent
              control={control}
              options={MORTGAGE_RATE_OPTIONS}
              label={"Search Category"}
              variant="secondary"
              name={"searchCategory"}
              defaultValue={getInitialSearchCategoryOption()}
              closeMenuOnSelect={true}
            />
            <div className="relative">
              <Input label={"Search"} {...register("search")} />
              {getValues("search") && (
                <svg
                  className="absolute right-3 bottom-[2.5rem] h-1/4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={clearSearch}
                >
                  <path
                    fill="black"
                    d="m23.666 2.683-2.35-2.35L12 9.65 2.683.333l-2.35 2.35L9.65 12 .333 21.317l2.35 2.35L12 14.35l9.316 9.317 2.35-2.35L14.35 12l9.316-9.317Z"
                  />
                </svg>
              )}
            </div>
          </form>
        </div>
        <div className="mt-6 overflow-y-auto overflow-x-auto">
          <DashboardTable
            columns={columns}
            rows={data?.data || []}
            total_count={data?.total_count ?? 0}
            isLoading={loading}
            isLoadingPagination={loadingPagination}
            page={page}
            page_size={PAGE_SIZE}
            handlePageChange={handlePageChange}
            sort={sort}
            handleSort={handleSort}
            showIndex={false}
          />
        </div>
      </div>
    </div>
  );
};

export default MortgageAnalysis;
