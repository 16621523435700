import Graphic from "@arcgis/core/Graphic.js";

export interface IOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}

export interface ISearchFormValues {
  [SearchFieldIdName.Parcel]: IOption[];
  [SearchFieldIdName.UniqueTax]: IOption[];
  [SearchFieldIdName.AlternateTax]: IOption[];
  [SearchFieldIdName.ParcelLid]: IOption[];
  [SearchFieldLocationName.OwnerName]: IOption[];
  [SearchFieldLocationName.OwnerAddress]: IOption[];
  [SearchFieldLocationName.State]: IOption;
  [SearchFieldLocationName.County]: IOption[];
  [SearchFieldLocationName.Township]: IOption[];
  [SearchFieldLocationName.Range]: IOption[];
  [SearchFieldLocationName.Section]: IOption[];
  [SearchFieldLocationName.Block]: IOption[];
  [SearchFieldLocationName.Lot]: IOption[];
}

export enum SearchFieldIdName {
  Parcel = "parcel_apn",
  UniqueTax = "uniqueTax",
  AlternateTax = "alternateTax",
  ParcelLid = "parcel_lid",
}

export enum SearchFieldLocationName {
  OwnerName = "owner_name",
  OwnerAddress = "site_address",
  State = "state",
  County = "county",
  Township = "township",
  Range = "range",
  Section = "section",
  Block="block_number",
  Lot="lot_number"
}

export interface ISearchFields {
  label: string;
  name: SearchFieldIdName | SearchFieldLocationName;
  placeholder?: string;
  options?: IOption[];
  isMulti: boolean;
  prevOption?: SearchFieldIdName | SearchFieldLocationName;
  queryFieldName?: string;
  defaultValue?: IOption;
  searchSubstrings?: boolean;
  required?: boolean;
}

export interface IMapSearchQuery {
  parcel_lid?: string[];
  parcel_apn?: string[];
  unique_taxapn?: string[];
  alternate_taxapn?: string[];
  owner_name?: string[];
  site_address?: string[];
  county?: string[];
  section?: string[];
  township?: string[];
  range?: string[];
  block_number?: string[];
  lot_number?: string[];
  page?: number;
  page_size?: number;
  sort_by?: string;
  sort_order?: string;
  site_state: string;
}

export interface IMapSearchResult {
  parcel_lid: string;
  owner_name: string;
  description: string;
  county: string;
  section: string;
  township: string;
  range: string;
  block_number: string;
  lot_number: string;
  mortgage?: boolean;
  ucc?: string;
}

interface Parcel {
  parcelFeature: Graphic;
}

export type ParcelResult = IMapSearchResult & Parcel;
