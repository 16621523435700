import clsx from "clsx";
import React from "react";

interface IInput {
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  errorMessage?: string;
  readOnly?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, IInput>(
  (
    {
      label,
      placeholder,
      type,
      name,
      errorMessage,
      readOnly = false,
      ...props
    },
    ref
  ) => {
    return (
      <div>
        <div
          className={clsx(
            "h-full bg-boundsGray-100 rounded-t-md border-b border-white relative flex flex-col py-3 px-2 justify-between",
            "xl:pt-3 xl:px-4 xl:pb-2"
          )}
        >
          <label className="text-xs text-black">{label}</label>
          <input
            ref={ref}
            name={name}
            className={clsx(
              "bg-transparent focus:outline-none placeholder-boundsPurple-100 !text-boundsPurple-400 font-sm",
              readOnly && "cursor-default"
            )}
            placeholder={placeholder}
            type={type}
            readOnly={readOnly}
            {...props}
          />
        </div>
        {errorMessage && (
          <span className="font-light text-[12px] text-red-500">
            {errorMessage}
          </span>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
