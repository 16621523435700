interface MoneyFormatOptions {
  locale?: string;
  currency?: string;
  showDecimals?: boolean; // New option to control decimal display
  useMillionAbbreviation?: boolean; //New Option to control M display
}

export const formatMoney = (
  amount?: number,
  options?: MoneyFormatOptions
): string => {
  if (!amount) {
    return "0.00";
  }

  const {
    locale = "en-US",
    currency = "USD",
    showDecimals = true, // By default, we show decimals
    useMillionAbbreviation = false, // New option to control the use of 'M' for millions
  } = options || {};

  // Check if the amount is 1 million or greater and useMillionAbbreviation is true
  if (useMillionAbbreviation && Math.abs(amount) >= 1000000) {
    const millionAmount = amount / 1000000;
    const formattedMillions = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }).format(millionAmount);

    // Replace the currency symbol if it's at the end
    return formattedMillions.replace(/\s*[^\d.,]+\s*$/, "") + "M";
  }

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: showDecimals ? 2 : 0, // Show decimals only if showDecimals is true
    maximumFractionDigits: showDecimals ? 2 : 0, // Same here
  }).format(amount);
};

export const formatInterestRate = (rate: string): string => {
  const parsedRate = parseFloat(rate);
  const sign = parsedRate >= 0 ? "" : "-";
  const formattedRate = sign + Math.abs(parsedRate).toFixed(2) + "%";

  return formattedRate;
};

export const capitalizeEachWord = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
