import clsx from "clsx";
import React from "react";

const DetailCard = ({
  label,
  content,
  disabled,
}: {
  label: string;
  content: string | string[] | number;
  disabled?: boolean;
}) => {
  return (
    <div
      className={clsx(
        "h-full bg-boundsGray-100 rounded-t-md border-b border-white relative flex flex-col py-3 px-2 justify-between",
        "xl:pt-3 xl:px-4 xl:pb-2"
      )}
    >
      <label className="text-xs text-black">{label}</label>
      <h1
        className={clsx(
          "bg-transparent focus:outline-none placeholder-boundsPurple-100 !text-boundsPurple-400 font-sm",
          disabled && "cursor-default"
        )}
      >
        {content}
      </h1>
    </div>
  );
};

export default DetailCard;
