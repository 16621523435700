import React from "react";
import { OptionProps } from "react-select";
import CheckIcon from "../../icons/Check.Icon";
import SquareIcon from "../../icons/Square.icon";
import { IOption } from "../../../types/search";

interface CheckboxOptionProps extends OptionProps<IOption, boolean> {
  isMulti: boolean;
}

const CheckboxOption: React.FC<CheckboxOptionProps> = ({
  innerProps,
  isSelected,
  isDisabled,
  label,
  isMulti = false,
}) => {
  return (
    <div
      className="flex items-center gap-2 px-4 py-1 w-full flex-1"
      {...innerProps}
    >
      {isMulti && (
        <div className="w-[18px]">
          {isDisabled ? null : isSelected ? (
            <CheckIcon width={18} height={18} />
          ) : (
            <SquareIcon width={18} height={18} />
          )}
        </div>
      )}
      <span className="overflow-hidden text-ellipsis whitespace-nowrap max-w-full cursor-pointer">
        {label}
      </span>
    </div>
  );
};

export default CheckboxOption;
